<template>
  <section
    class="sub-wrap"
  >
    <div
      ref="content"
      v-dompurify-html="content"
    />
  </section>
</template>

<script>
export default {
  data() {
    return {
      content: null,
    }
  },
  created() {
    window.addEventListener("message", this.receiveMessage, false)
  },
  mounted() {
  },
  methods: {
    receiveMessage(e) {
      if (e.origin === process.env.VUE_APP_ADMIN_URL) {
        this.content = e.data

        if (this.content.indexOf('<script') > 0) {
          const text = this.content.substring(this.content.indexOf('script>') + 7, this.content.lastIndexOf('script>') - 2)

          this.script = document.createElement('script')

          this.script.setAttribute('type', 'module')

          this.script.text = text

          setTimeout(() => {
            this.$refs.content.appendChild(this.script)
          }, 100)
        }
      }
    },
  },
}
</script>

<style>
.drPickWrap {
  min-width: auto !important;
  overflow-y: auto !important;
}

.sub-wrap {
  height: auto !important;
}
</style>
